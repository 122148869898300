.irrational {
  text-align: center;
  svg {
    width: 50vw;
    height: auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .irrational {
    svg {
      width: 80vw;
    }
  }
}
