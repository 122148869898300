.tabpan-wi {
  padding: 0 1rem 0 0rem;
  p {
    padding: 0 .5rem;
    text-align: justify;
  }
}

.service-box {
  width: 50vw;
  margin: 2rem auto;
}

.service-tab {
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border: 1px solid #dadada;
}

.title-cente-c {
  padding: 1rem;
  margin: 2rem;
  background-color: black;
  text-align: center;
  h2 {
    text-shadow: 1px 1px 2px black, 0 0 1em rgb(255, 255, 255), 0 0 0.2em rgb(221, 221, 226);
    span {
      color: white;
    }
  }
}

.sp-irra {
  color: #4AB8E3 !important;
}

@media screen and (max-width: 767px) {
  .service-box {
    width: 100vw;
  }
  .service-tab {
    padding: 1rem 0;
  }
}
