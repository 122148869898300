#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.hero-logo {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-butt {
  margin-top: 5rem;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
}

.hero-box {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 414px) {
  
}
